 const  News = [
    {
        "id":"1",
        "perma":"MI-NEW-IPL-SQUAD",
     "Heading":"MI NEW IPL SQUAD",
     "img":"/images/Mi.png",
     "content":"check the updated squad of MI 2024",
    },
    {
        "id":"2",
        "perma":"CSK-NEW-IPL-SQUAD",
        "Heading":"CSK NEW IPL SQUAD",
        "img":"./images/csk.jpg",
        "content":"check the updated squad of csk 2024",
       },
       {
        "id":"3",
        "perma":"RCB-NEW-IPL-SQUAD",
        "Heading":"RCB NEW IPL SQUAD",
        "img":"./images/Rcb.png",
        "content":"check the updated squad of RCB 2024",
       },
       {
        "id":"4",
       "perma" :'Rohit-Captaincy-news',
        "Heading":"Rohit Sharma Captaincy news ",
        "content":"check the latest news about rohit sharma removed from MI captaincy.",
        "img":"./images/rohit.jpg",
       },
       {
        "id":"5",
        "perma":"Mohammad-Shami-Ipl-ruled-out",
        "Heading":"Mohammad Shami Ipl ruled out ",
        "img":"https://bcciplayerimages.s3.ap-south-1.amazonaws.com/ipl/IPLHeadshot2023/47.png",
       },
]  
export default News