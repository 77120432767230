const MatchData=[
  {
    "id":"19",
     "perma":"/kkr-vs-dc-dream11-prediction" ,
     "img":"/images/kkr vs dc.png",
    "Date":"29-April-2024",
    "league":"IPL",
    "Heading":"KKR vs DC",
    "content":"check the updated squad of MI 2024",
   },
  {
    "id":"18",
     "perma":"pak-vs-nz-2nd-t20i-dream11-prediction" ,
     "img":"/images/pak vs nz poster.png",
    "Date":"18-April-2024",
    "league":"T20",
    "Heading":"PAK vs NZ",
    "content":"check the updated squad of MI 2024",
   },
  {
    "id":"17",
     "perma":"gt-vs-dc-dream11-predictions-fantasy-tips" ,
     "img":"/",
    "Date":"17-April-2024",
    "league":"IPL",
    "Heading":"GT vs DC",
    "content":"check the updated squad of MI 2024",
   },
  {
    "id":"12",
     "perma":"kkr-vs-rr-dream11-predictions-fantasy-tips" ,
     "img":"/images/kkr vs rr poster.png",
    "Date":"16-April-2024",
    "league":"IPL",
    "Heading":"KKR vs RR",
    "content":"check the updated squad of MI 2024",
   },
  {
    "id":"1",
     "perma":"pbks-vs-rr-dream11-prediction" ,
    "Date":"14-April-2024",
    "img":"/images/pbks vs rr poster.png",
    "league":"IPL",
    "Heading":"PBKS vs RR",
    "content":"check the updated squad of MI 2024",
   },
  {
    "id":"5",
     "perma":"rr-vs-gt-dream11-prediction-ipl-2024",
     "img":"/images/rr vs lsg compress poster.png",
    "Date":"09-April-2024",
    "league":"IPL",
    "Heading":"RR vs GT",
   },
  {
    "id":"16",
     "perma":"pbks-vs-srh-dream11-predictions" ,
     "img":"/images/pbksvssrh compressed.png",
    "Date":"09-mar-2024",
    "league":"IPL",
    "Heading":"PBKS vs SRH",
   },
  {
    "id":"11",
     "perma":"rcb-vs-rr-dream11-prediction-today-match-prediction-ipl-2024" ,
     "img":"/images/rcb vs rr poster.png",
    "Date":"11-mar-2024",
    "league":"IPL",
    "Heading":"RCB vs RR",
    "content":"check the updated squad of MI 2024",
   },
  {
    "id":"15",
     "perma":"srh-vs-gt-dream11-prediction-today-match-prediction-ipl-2024",
     "img":"/images/SRH VS GT.png",
    "Date":"31-March-2024",
    "league":"IPL",
    "Heading":"SRH vs GT",
   },
  {
    "id":"14",
     "perma":"lsg-vs-pbks-dream11-prediction-today-match-prediction-ipl-2024",
     "img":"/images/lsgvspbksposter.png",
    "Date":"30-March-2024",
    "league":"IPL",
    "Heading":"LSG vs PBKS",
   },
  {
    "id":"6",
     "perma":"rcb-vs-kkr-dream11-prediction-today-match-prediction-match10-ipl-2024",
     "img":"/images/rcbvskkrposter.jpg",
    "Date":"29-March-2024",
    "league":"IPL",
    "Heading":"RCB vs KKR",
   },
  {
    "id":"13",
     "perma":"rcb-vs-pbks-dream11-prediction-today-match-ipl-2024" ,
     "img":"/images/VS.png",
    "Date":"25-mar-2024",
    "league":"IPL",
    "Heading":"RCB vs PBKS",
   },
   {
    "id":"8",
     "perma":"srh-vs-kkr-dream11-predictions-ipl-2024",
     "img":"/images/SRH vs KKR.png",
    "Date":"6-MAR-2024",
    "league":"IPL",
    "Heading":"SRH vs KKR",
   },
  
  {
    "id":"2",
    "perma":"pbks-vs-dc-match-predictions",
    "img":"/images/pbks vs dc.png",

    "Date":"23-march-2024",

    "league": "IPL",

       "Heading":"pbks vs dc",
     
       "content":"check the updated squad of csk 2024",
      },
  {
    "id":"10",
     "perma":"MUS-vs-QUE/Match-Predictions",
    "Date":"12-MAR-2024",
   "img":"/images/musvsque.jpg",
    "league":"PSL",
    "Heading":"MUS VS QUE",
   },
  {
    "id":"9",
     "perma":"LAH-vs-ISL/Match-Predictions",
    "Date":"6-MAR-2024",
    "img":"/images/lqvsiu.jpg",
    "league":"PSL",
    "Heading":"LAH VS ISL",
   },
 
  {
    "id":"3",
   "perma":"MUS-vs-LAQ/MatchPredictions",
    "Date":"27-feb-2024",
   "img":"/images/musvslaq.jpg",
    "league": "PSL",
       "Heading":"MUS vs LAQ",
       "content":"check the fantasy match prediction of MUS vs ISL 2024",
      },
  {
    "id":"7",
     "perma":"LAH-vs-KAR/MatchPredictions",
    "Date":"24-feb-2024",
    "league":"PSL",
    "Heading":"LAH vs KAR",
   },
]
export default MatchData