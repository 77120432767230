import React from 'react'
import './Trending.css'
import { Helmet } from 'react-helmet'
function Trending5() {

  return (
    <div>
      <div className='container trending' >
    <Helmet>
     <title>IS IPL Scripted Or Not-</title>
     <meta name="description" content="If you want to know Is ipl Scripted or not so check here you will get your answer. " />
     <meta name="keywords" content=" Is Ipl Scripted, is ipl scripted or not,Ipl is fixed or not." />
     <meta name="robots" content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large"/>
   </Helmet>
   <h1 className='middle-h1 my-2 text-center'>Is IPL Scripted Or Not</h1>
   <img src="https://www.iwmbuzz.com/wp-content/uploads/2020/02/all-the-fixing-controversies-of-ipl-over-the-years-920x518.jpg" alt="is ipl scripted or not" className='middle1-img my-3' />
   <p className='my-3'> <strong>I</strong>f you  want to know <strong>ipl is fixed or not</strong> so you are at the right place so answer is <strong>NO ipl is not fixed</strong> because Ipl is hosted by BCCI. BCCI is the cricket board of india and BCCI do not support any type of fixing in cricket so Bcci strictly watch the players and their staff so there is no chance of fixing and scripted matches but many times we saw the things there are some people and teams were involved in fixing and you see Bcci banned these players and teams for sometime like sometime  before you saw chennai and rajasthan  banned for 2 years from IPL.</p> <p> But after this incident Bcci taken some strict actions against the players and teams who involved in fixing things. so there is a very rare chance of fixing matches in the ipl.so at this time there is no chance of fixing matches or scripted  matches in ipl. </p>
  
  
   <h2 className='trend-heading'> Rajasthan IPL BAN  story</h2>
 <p>The story started in 2013 where Delhi  police were monitoring the all fixing activities and when Delhi bookie start their network so police starting tap their phone calls and the process started 2 months before ipl in february.delhi police cyber cell got a news that underworld people planning to fix the matches with the help of big players.In may month maharashtra ATS head got a clue related to match fixing .so the information is an IPL Umpire involved in match fixing and on the other hand delhi police got some call recoding between Bookie and players.after monitoring these activities they find that Rajasthan Royals  involved in this fixing .police find that player did the same actions and signals that he heard in call recordings between player and Bookie in 5th may and 9th May matches.</p><p> Delhi police monitoring the activities and <strong> they find S Sreesanth were involved with Ajit Chandila and Jiju Janardhan involved in alleged spot fixing </strong>.police arrested these cricketers so after the more investing Delhi police finds that <strong>CO-Owner of Rajasthan Royals Raj Kundra</strong>  was also involved in illegal betting and kundra confesses that so Rajasthan Royals team management suspended his shares.Raj Kundra  banned Kundra from all cricketing activities under its ambit. </p>
  so this is the incident where find some players guilty and involved in illegal spot fixing in IPL
  <h3 className='trend-heading'>Conclusion</h3>
  <p>In past we some incident where  players and team owner involved in illegal spot fixing but they were arrested and their career was ruined and after that Bcci passed some strict rules on alleged spot fixing and authorities monitor these activities carefully so the chance of match fixing or scripting is very less and rare chance because this type of activity spoil the career of players and banned from cricket. so in present <strong>IPL Matches is not fixed or scripted</strong>due to strict watch on players and management activities and everyone known the circumstances if anyone involved in this type of activities.so the answer of you question <strong>is ipl scripted or not </strong> the answer is <strong>NO beacause with the time rules and Bcci take this issue seriously </strong>and create rules for alleged illegal spot fixing.so the chances of fixing is very rare.</p>
  <h4>FAQ</h4>
  <ol>
   <a href="/Rohit-Captaincy-news"> <li>Rohit Sharma Captaincy news</li></a>
   <a href="/Mohammad-Shami-Ipl-ruled-out"> <li>Mohammad Shami Ipl ruled out </li></a>
   <a href="/Why-We-Should-Play-Fantasy-Cricket"><li>Why We Shoul Play Fantasy Cricket</li></a>
    
  </ol>
 </div>

    </div>
  )
}

export default Trending5
